import React, { useEffect, useMemo, useRef, useState } from 'react';
import {
  Order_By,
  useGetAllCategoryKnowledgeQuery,
  useGetSeriesHomeQuery,
} from '@/generated/graphql';
import { LazyImage } from '../shared';
import Link from 'next/link';
import { UrlLink } from '@/configs/urlLink';
import { LeftArrowIcon1, RightArrowIcon1 } from '@/assets/icons';
import Slider, { Settings } from 'react-slick';
import { HomeSeriesCard } from './HomeSeriesCard';

const KnowledgeSeriesSection = () => {
  const { data: dataSeriesCategory } = useGetAllCategoryKnowledgeQuery({
    variables: {
      orderBy: [
        {
          series_order: Order_By.AscNullsLast,
        },
      ],
    },
  });

  const filteredCategoryNotEmpty = dataSeriesCategory?.course_category.filter(
    outerItem =>
      outerItem.series_course_categories.some(
        innerItem => innerItem.series?.series_knowledges?.length > 0,
      ),
  );

  const [currentCategoryName, setCurrentCategoryName] = useState<string>(
    filteredCategoryNotEmpty?.[0]?.id ?? '',
  );

  const { data: dataSeries } = useGetSeriesHomeQuery({
    variables: {
      where: {
        series_course_categories: {
          course_category_id: { _eq: currentCategoryName },
        },
      },
      orderBy: [
        {
          order: Order_By.AscNullsLast,
        },
      ],
    },
  });

  const customSlider = useRef<Slider>(null);
  const goToPrev = () => customSlider?.current?.slickPrev();
  const goToNext = () => customSlider?.current?.slickNext();

  useEffect(() => {
    setCurrentCategoryName(filteredCategoryNotEmpty?.[0]?.id ?? '');
  }, [filteredCategoryNotEmpty?.[0]?.id]);

  const listSeries = useMemo(() => {
    return dataSeries?.series.filter(item => item.series_knowledges.length > 0);
  }, [currentCategoryName, dataSeries]);

  const featureSeries = useMemo(() => {
    return listSeries?.[0];
  }, [listSeries]);

  const lengSlide = (listSeries?.length || 0) - 1;

  const settingsMobile: Settings = {
    dots: false,
    infinite: false,
    arrows: false,
    variableWidth: true,
  };

  return (
    <div className="bg-white">
      <div className="container py-6">
        <div className="bg-white">
          <div className="border-neutral-grayLight flex flex-wrap items-center justify-between p-3">
            <div className="md:pb-4">
              <h2 className="font-header py-2 text-2xl font-semibold md:py-0">
                Bài Viết Kiến Thức
              </h2>
            </div>
            <div className="relative w-full">
              <div className="no-scrollbar w-full overflow-x-scroll pb-2 text-center">
                <ul className="flex">
                  {filteredCategoryNotEmpty?.map((item, index) => (
                    <li className="" key={index}>
                      <button
                        onClick={() => setCurrentCategoryName(item.id)}
                        className={`inline-block rounded-t-lg border-b-2  px-4 py-2 ${
                          currentCategoryName === item.id
                            ? 'border-primary-eastern text-primary-eastern'
                            : 'border-transparent'
                        }`}
                      >
                        <p
                          className={`text-PC-Title-Large md:text-PC-Button-Medium w-max text-[#020509] ${
                            currentCategoryName === item.id
                              ? 'text-primary-eastern'
                              : ''
                          }`}
                        >
                          {item.name}
                        </p>
                      </button>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
          </div>
          {featureSeries ? (
            <Link
              className="hover:text-primary-eastern flex bg-white py-2"
              href={`${UrlLink.knowledge.series}/${featureSeries?.slug}`}
            >
              <div className="hidden bg-white md:flex md:w-full md:flex-row md:px-2 lg:px-3">
                <div className="relative h-[236px] w-full gap-2 md:h-[332px]">
                  <LazyImage
                    src={featureSeries?.thumbnail || ''}
                    alt="Thumbnail course"
                    fill
                    sizes="25vw"
                    className="object-fit"
                  />
                </div>
                <div className="flex w-full flex-col gap-3 py-8 md:px-4 lg:px-6 ">
                  <h3 className="text-PC-Display-H5 md:text-PC-Display-H3 font-header">
                    {featureSeries?.title}
                  </h3>
                  <p className="text-PC-Title-Regular font-sans text-[14px]">
                    {featureSeries?.series_knowledges.length} Bài viết
                  </p>
                </div>
              </div>
              <div className="w-full bg-white md:hidden">
                <div className="relative h-[234px] w-full">
                  <LazyImage
                    src={featureSeries?.thumbnail || ''}
                    alt="Thumbnail course"
                    fill
                    sizes="25vw"
                    className="object-fit"
                  />
                </div>
                <div className="flex w-full flex-col gap-3 px-3 py-4">
                  <h3 className="font-header text-PC-Display-H5">
                    {featureSeries?.title}
                  </h3>
                  <p className="text-neutral-grayDark text-PC-Caption-Medium font-sans font-semibold">
                    {featureSeries?.series_knowledges.length} Bài viết
                  </p>
                </div>
              </div>
            </Link>
          ) : (
            <></>
          )}
        </div>
        <div className="slider-container relative py-6">
          {listSeries?.length ? (
            <div>
              <div className="">
                <Slider
                  {...{
                    ...settingsMobile,
                    infinite: lengSlide < 4 ? false : true,
                  }}
                  ref={customSlider}
                >
                  {listSeries
                    ?.filter(item => item.id != featureSeries?.id)
                    ?.map(item => {
                      return (
                        <div key={item?.id} className="!w-[288px] p-2">
                          <HomeSeriesCard
                            thumbnail={item?.thumbnail}
                            title={item?.title}
                            slug={item?.slug}
                            knowledges={item?.series_knowledges?.map(
                              item => item.knowledge,
                            )}
                          />
                        </div>
                      );
                    })}
                </Slider>
                <div className="hidden md:block">
                  <div
                    className={`left-[-50px] top-1/2  -translate-y-1/2 md:absolute ${
                      Number(listSeries?.length ?? 0) > 5 ? 'block' : 'hidden'
                    }`}
                  >
                    <button onClick={goToPrev} className="cursor-pointer">
                      <LeftArrowIcon1 />
                    </button>
                  </div>
                  <div
                    className={`right-[-50px] top-1/2  -translate-y-1/2 md:absolute ${
                      Number(listSeries?.length ?? 0) > 5 ? 'block' : 'hidden'
                    }`}
                  >
                    <button onClick={goToNext}>
                      <RightArrowIcon1 className="cursor-point" />
                    </button>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <></>
          )}
        </div>
      </div>
    </div>
  );
};

export default KnowledgeSeriesSection;
